


@media screen and (min-width:2150px) {

    .Journeybtm-icon-grid .icon-grid-data .icon-one::after {
        height: 68%;
    }
    .Journeybtm-icon-grid .icon-data-main.icon-one:nth-child(even)::after {
        transform: rotate(181deg);
        top: -42px;
    }

}
@media screen and (min-width:2800px) {

    .Journeybtm-icon-grid .icon-grid-data .icon-one::after {
        height: 74%;
    }

}
@media screen and (min-width:3800px) {

    .Journeybtm-icon-grid .icon-grid-data .icon-one::after {
        height: 100%;
    }
    .Journeybtm-icon-grid .icon-data-main.icon-one:nth-child(even)::after {
        transform: rotate(181deg);
        top: -72px;
    }

}
@media screen and (min-width:5600px) {
    .Journeybtm-icon-grid .icon-grid-data .icon-one::after { 
        background-size: 100% 100%;
    }
    .Journeybtm-icon-grid .icon-data-main.icon-one:nth-child(even)::after {
        top: -90px;
    }
}

@media screen and (max-width:1700px) {
    .signup_parent .tab-content {
        overflow: scroll;
        overflow-x: hidden;
        height: 450px
    }
}

@media screen and (max-width:1600px) {
    .new_animation_bestof span {
        left: 25%
    }


    /* changes header 28-12-23 s */
    .user_profile a {
        display: flex;
        align-items: center;
    }

    .activities_info p,
    .header_menu_btn {
        display: flex;
    }

    /* .header_menu_list ul li {
        margin: 0 9px;
    } */

    .dashboard_img_text h5 {
        font-size: 28px;
    }

    .header_menu_list ul li a {
        display: flex;
        align-items: center;
    }

    .profile-provider-main .save_chnages_btn {
        margin-top: 85px;
    }

    .provider-review-main .order_list_product .order_list_inner table tr td:first-child {
        width: 111px
    }
}

@media screen and (max-width:1440px) {
    /* .box_list_img {
        width: 200px;
        height: 200px;
    } */


    /* new css 15-3-24 */
    .provider-review-main .reviews-dynamic-values {
        font-size: 14px;
        width: 100%;
    }

    .order_list_product .table-responsive {
        overflow-x: scroll;
    }

    .main_table span {
        width: 200px;
    }

    .order_list_product .order_list_inner table td span {
        font-size: 14px;

    }

    .provider-review-main .order_list_product .order_list_inner table tr td:first-child {
        width: 109px;
        display: flex;
        justify-content: start;
    }

    .main_table th {
        display: flex;
    }

    /* 26-3-24 s thankyou page */
    .cartname_leftpart h1 {
        font-size: 24px;
    }

    .order_details_table_order {
        padding: 10px;
    }

    /* 28-3-24 */
    .info_date_button .heading_search_inner h2 {
        font-size: 55px;
    }
/* ------------ as25 --------------- */
    .cart_order_sectionmaindiv{
        padding: 0px;
    }

}


@media screen and (max-width:1280px) {
    .latest_left_inner p {
        max-width: 75%
    }

    .booking_summary .Upcoming_booking_table.table-responsive,
    .upcoming_detail .Upcoming_booking_table.table-responsive {
        overflow: scroll
    }

    .booking_summary .Upcoming_booking_table table tr td,
    .upcoming_detail .Upcoming_booking_table table tr td {
        min-width: 130px
    }

    .upcoming_detail .order_details_table .Upcoming_booking_table table tbody tr td:first-child {
        min-width: 350px
    }

    .header_menu_btn button:nth-child(2) {
        padding: 8px 13px
    }

    .header_menu_list ul li {
        margin: 0 2px
    }

    .group_main_inner_main,
    span.deal_details {
        display: block
    }

    .number_price {
        margin-top: 18px
    }

    .new-group-main .no_of_people.new-people-inner {
        width: 100%
    }

    .doughnut_text h5 {
        top: 40%;
        left: 35%;
        font-size: 14px
    }

    .doughnut_text h6 {
        top: 50%;
        left: 42%;
        font-size: 13px
    }

    .cart_product_list .radt_price p {
        display: flex
    }

    .cart_product_list .deal_details .percentage,
    .tax_distribution {
        font-size: 14px
    }

    .tax_list li {
        width: 137px
    }


    /* changes 28-12-23 s ********** */
    .user_profile a {
        display: flex;
        align-items: center;
    }

    .user_profile {
        display: flex;
    }

    .dashboard_img_text h5 {
        font-size: 22px;
    }

    .dashboard_section_main {
        padding: 15px;
    }

    .header_menu_list ul {
        gap: 5px;
    }

    .provider_my_tour .btn_list button {
        padding: 5px 10px;
    }

    .box__list_dis {
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 25px 0px;
        justify-content: flex-start;
    }

    .text_image_loc {
        position: relative;
        top: 0;
    }

    .user_edit_main .tax_list li {
        display: block;
    }

    /* 28-3-24 s */

    .info_date_button .heading_search_inner h2 {
        font-size: 28px;
    }

    .listing__Page-banner::before {
        top: 20%;
    }

}

@media screen and (max-width:1200px) {
    :root {
        --heading-mid-12: 0.75rem;
        --heading-mid-14: 0.875rem;
        --heading-mid-16: 0.875rem;
        --heading-big-18: 1rem;
        --heading-big-24: 1.2rem;
        --heading-big-38: 2rem;
        --heading-big-20: 1.1rem
    }

    .expand_text h1,
    .login_form_box {
        max-width: 100%
    }

    .login_text p {
        line-height: 18px
    }

    .login_text h2 {
        font-size: 60px;
        line-height: 63px
    }

    .Wishlist_main_componemt .div_last_main {
        height: auto;
        align-items: start;
        margin-top: 15px
    }

    .Contact_info_right,
    .SendUs_message_left {
        width: 50%
    }

    .expand_box_main {
        min-height: 109px
    }

    .input_search .btn_search {
        padding: 10px 30px;
        top: 5px
    }

    .provider_account_main .User_sidebar_main {
        width: 15.5rem
    }

    .provider_all_component.provider_all_data {
        margin-left: 17.5rem
    }

    /* new add */
    .listinner-list {
        height: 350px;
        overflow: scroll;
        overflow-x: hidden;
    }

       /* ========= 19-09-24 ashu ===== */
   .culture_list_info_new .info_date_button .info__date_inner{
    width: 30%;
}

}

@media screen and (max-width:1170px) {
    .Wishlist_main_componemt .prd_location_main {
        width: 70%
    }

    .Wishlist_main_componemt .remove_btn button {
        padding: 9px
    }

    .header_logo_image img {
        width: 120px
    }

    .input_search input {
        height: 50px
    }

    .header_menu_btn button:nth-child(2) {
        padding: 7px;
        margin-left: 7px
    }

    .header_menu_btn {
        text-align: start
    }

    .div_experience_new .owl-theme .owl-nav {
        display: none
    }

    .latest_left_inner p {
        max-width: 80%
    }

    .recommended_list_img_new img {
        border-radius: 8px;
        width: 135px;
        height: 100px
    }

    .order_details_main .order_inner {
        flex-direction: row-reverse
    }

    .order_details_main .order_all_left_info {
        display: block
    }

    .order_details_main .order_all_left_info .order_fist {
        width: 100%;
        margin-bottom: 10px;
        display: block
    }

    .order_details_main .order_main_info {
        align-items: flex-start;
        flex-direction: column-reverse
    }

    .order-date-info {
        display: flex;
        flex-direction: column-reverse
    }

    .mytket_box_main {
        min-height: 214px
    }

    .affiliate_text h1 {
        font-size: 32px
    }

    .expand_text h1 {
        max-width: 100%
    }

    .input_search .btn_search {
        padding: 7px 19px;
        top: 7px
    }

    .cart_product_list .radt_price p,
    .dashboard_img_text h5 {
        font-size: 20px
    }

    .cart_product_list {
        padding: 13px
    }

    .line_chart_main svg.css-13aj3tc-MuiChartsSurface-root {
        width: 500px
    }

    .doughnut_text h5 {
        top: 40%;
        left: 38%
    }

    .doughnut_text h6 {
        top: 50%;
        left: 42%
    }

    .Wishlist_main_componemt .div_last_main {
        flex-direction: row
    }

    /* new changes 28-12-23 shivi ********************** */
    .cart_product_list .radt_price p,
    .dashboard_img_text h5 {
        font-size: 18px;
    }

    .haeder_new_btn.newclass-login .lang_drop_down,
    .paginate_all ul li.previous {
        margin-right: 15px;
    }

    .haeder_new_btn .dropdown-toggle::after,
    .language_change_header .dropdown-toggle::after {
        top: 18px;
    }

    .Wishlist_main_componemt .div_last_main {
        gap: 8px;
    }

    .div_last_main .view_product_btn_list {
        margin-top: 0px;
    }

    .tourfile_title_section .checkbox_product_title {
        display: flex;
    }

    /* new changes mytket s */
    .activity_details .checkout_product_detail_list .list li {
        font-size: 14px;
    }

    /* new 26-3-24 */
    .cartyour_bookdetail p {
        font-size: 14px;
    }

    .cartname_leftpart h1 {
        font-size: 24px;
    }
}

@media screen and (max-width:1024px) {
    .container {
        max-width: 95%
    }

    .Cultural_Main .owl-carousel .owl-nav button.owl-prev {
        left: -42px
    }

    .Cultural_Main .owl-carousel .owl-nav button.owl-next {
        right: -42px
    }

    .new_animation_bestof span {
        left: 13%
    }

    .save_chnages_btn button {
        padding: 10px 20px
    }

    .recommended_list_content_new {
        margin-left: 0;
        padding: 17px 5px 0 4px
    }

    .recommended_list_img_new img {
        border-radius: 8px;
        width: 100%;
        height: 145px
    }

    .recommended_list_img_new {
        height: 80px
    }

    .aboutus_text h1 {
        font-size: 36px;
        line-height: 45px
    }

    .right_profile_right1 {
        margin-left: 15px
    }

    .service_img img {
        width: 80px;
        height: 80px
    }

    .service_section_box {
        padding: 12px
    }

    .our_team_carousel_section .owl-nav button.owl-prev {
        left: -50px
    }

    .our_team_carousel_section .owl-nav button.owl-next {
        right: -50px
    }

    .affiliate_text h1,
    .expand_text h1 {
        max-width: 100%
    }

    .SendUs_message_left {
        width: 70%
    }

    .Contact_info_right {
        width: 50%
    }

    .travelguide .guide-desg h4 {
        font-size: 20px
    }

    .expand_box_main {
        margin-top: 13px;
        min-height: 99px
    }

    .expand_new_search {
        padding-top: 0
    }

    .company_title h2 {
        font-size: 24px
    }

    .part_heading h1 {
        font-size: 36px
    }

    .footer-section button.submit_btn {
        top: 66px
    }

    .images-page-unknow img {
        width: 60%
    }

    .Commission_text p {
        display: block
    }

    .Commission_text {
        margin-top: 10px
    }

    /* new css shivi */
    .cart_product_list .radt_price p,
    .dashboard_img_text h5 {
        font-size: 16px;
    }


    .stickysection_lists button {
        font-size: 14px;
        line-height: 17px;
    }

    /* ========= 19-09-24 ashu ===== */
   .culture_list_info_new .info_date_button .info__date_inner{
        width: 30%;
    }
}

@media screen and (max-width:991px) {
    /* ---- as25 -------- */
    .last_cardmaincardflex .third_lastcardmaindiv {
        width: 50%;
    }
    .last_cardmaincardflex .second_detail_mainmiddiv {
        width: 70%;
    }
    .last_cardmaincardflex .fist_imagemaindiv {
        width: 22%;
    }
    .cart_leftimagemain .cartone_img {
        padding: 20%;
        width: 100%;
    }

    /* -- as15 ---- */
    .knowbefore_gosection .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
        width: 75%;
    }

    /* // -- ------ */
    .wish_lsit_info {
        margin-top: 10px
    }

    .div_blogs_left .advertisement-banner,
    .header_menu_list ul,
    .login_mobile_text,
    .login_text.desktop_login_text,
    .mobile_div_image,
    .provider_slider_bar,
    .single-availabillity {
        display: none
    }

    .tab_new_inner {
        position: relative;
        margin-bottom: 15px;
        background: 0 0
    }

    .Recommended_tab_inner,
    .login_form_box {
        margin-top: 0
    }

    .header_sticky,
    .login_logo {
        padding: 10px 0
    }

    .header_menu_btn button:nth-child(2) {
        padding: 7px 17px;
        margin-left: 16px
    }

    .banner_menu_btn a {
        padding: 11px 18px;
        font-weight: 400
    }

    .Cultural_Main .owl-carousel .owl-nav button.owl-prev {
        left: -29px
    }

    .Cultural_Main .owl-carousel .owl-nav button.owl-next {
        right: -29px
    }

    .expand_box_main,
    .nav_mobile_main,
    .provider_speeddail_mobile {
        display: block
    }

    .nav_mobile_main .header_logo_image {
        text-align: center
    }

    .drawer_nav_mobile {
        padding: 15px
    }

    .mobile_drawer button {
        background: 0 0;
        border: none;
        float: right
    }

    .mobile_drawer .header_menu_btn button {
        float: left;
        margin: 6px;
        border: 1px solid;
        border-radius: 100px;
        padding: 7px 17px
    }

    .all_mobile_data {
        display: flex;
        justify-content: end;
        align-items: center
    }

    .mobile_drop_lang {
        display: block;
        margin-right: 8px
    }

    .mobile_drawer .header_menu_btn {
        display: flex;
        flex-direction: column
    }

    .drawer_nav_mobile ul {
        padding: 0;
        margin: 0
    }

    .drawer_nav_mobile ul li {
        list-style: none;
        text-align: left;
        margin: 15px 0
    }

    .drawer_nav_mobile ul li a {
        font-size: 18px
    }

    .drawer_nav_mobile span.header_menu_icon {
        margin-right: 7px
    }

    .new_animation_bestof span {
        line-height: 80px;
        top: 129px
    }

    .activity_about_main,
    .main_image_banner,
    .product_info_main {
        margin-bottom: 20px
    }

    .product_info_main .price_box {
        background: 0 0;
        box-shadow: none;
        padding: 0;
        margin-top: 10px
    }

    .inner_mainproduct_info {
        background: #fff;
        box-shadow: 0 20px 30px rgba(35, 42, 53, .15);
        border-radius: 8px;
        padding: 20px
    }

    .Why_book ul li {
        margin-bottom: 10px
    }

    .login_text.mobile_login_text {
        display: block;
        position: relative;
        width: 100%;
        max-width: 100%;
        left: 0;
        margin-top: 5%
    }

    .login_text {
        position: relative;
        top: auto;
        width: 100%;
        margin: 20px 0
    }

    .login_text p.login_content,
    .price_info input {
        width: 100%
    }

    .signup_parent .tab-content {
        height: 600px
    }

    .Wishlist_main_componemt .prd_crd_loaction p {
        margin-bottom: 0
    }

    .btn_search,
    .expand_image img,
    .filter_drop_down {
        margin-top: 15px
    }

    .Upcoming_booking_table.table-responsive {
        overflow: scroll
    }

    .Upcoming_booking_table table tr:first-child th:not(:last-child) {
        min-width: 150px
    }

    .latestblogs_left_bg_img {
        margin-bottom: 25px
    }

    .latestblogs_right_profile2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start
    }

    .right_profile_left2 {
        display: flex;
        justify-content: flex-start;
        align-items: center
    }

    .right_profile_right2 {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-left: 10px;
        padding: 10px 0
    }

    .right_profile_left2 span {
        margin-left: 18px
    }

    .right_profile_right2 span {
        margin-left: 24px
    }

    .right_profile_right2 img {
        width: 100%;
        margin-left: 0
    }

    .latest_left_inner p {
        max-width: 90%
    }

    .new_left_activity {
        order: 1
    }

    .top_post_main {
        padding: 0;
        margin: 30px 0
    }

    .latestblogs_right_profile1 {
        justify-content: space-between
    }

    .our_story_section::before {
        width: 50%;
        height: 86%;
        left: 0
    }

    .user_name_input {
        margin-top: 30px
    }

    .contactinfo_social_icon {
        padding: 68% 0 0
    }

    .provider_all_component.provider_all_data {
        margin-left: 1rem;
        margin-right: 1rem
    }

    .price_box_inner {
        flex-direction: column;
        gap: 10px
    }

    .price_info label {
        margin-right: 10px
    }

    .row.verticle_expand {
        margin-top: 40px
    }

    .expand_conten {
        display: flex;
        justify-content: center
    }

    .Share_icon p {
        max-width: 100%
    }

    .Share_icon {
        margin-top: 24px
    }

    .provider_account_main .profile_btm_link_tourside_bar {
        width: 100%;
        height: auto
    }

    .profile_btm_link_tourside_bar {
        position: inherit
    }

    /* header resposive shivi */
    .haeder_new_btn {
        display: none;
    }

    .banner_div_text {
        z-index: 1
    }

    .header--main {
        z-index: 999;
        top: 0px;
    }

    .mountain_img_sec {
        z-index: 1
    }

    .header--main .header_sticky {
        background: #fff;
    }

    .header--main .nav_mobile_main .header_logo_image {
        text-align: left;
    }

    .mobile_drop_lang {
        display: flex;
        align-items: center;
    }

    .search-icon-mobile button {
        display: block;
    }

    /* .modal-body-main.modal-body {
        position: absolute;
        top: 0px;
        width: 100%;
    } */
    .search-icon-mobile {
        display: none;
    }

    .header--main .drawer_nav_mobile ul li {
        margin: 23px 0;
    }

    .modal-header-div .modal-content {
        border: none !important;
        background: transparent !important;
    }

    .drawer_nav_mobile span.MuiBadge-badge.MuiBadge-standard {
        right: 12px;
    }

    .header_sticky,
    .login_logo {
        padding: 15px 0 10px;
    }

    /* accodion tour css */
    .provider_account_main .tourside_bar-accodion {
        background: #ffff;
        box-shadow: 0 20px 30px 0 rgba(35, 42, 53, .15);
        padding: 12px 20px;
        text-align: center;
        display: block;
    }

    .profile_btm_link_tourside_bar-accodion h2.tour-accodion {
        display: flex;
        justify-content: space-between;
        font-size: 26px;
        color: #232a35;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .profile_btm_link_tourside_bar-accodion li {
        text-align: left;
    }

    .provider_account_main .tourside_bar {
        display: none;
    }

    .provider_account_main .profile_btm_link_tourside_bar {
        box-shadow: none;
    }

    .provider_account_main .tourside_bar-accodion ul {
        list-style: none;
        padding: 0px;
        margin-bottom: 0px;
    }

    .provider_account_main .profile_btm_link_tourside_bar {
        border-radius: 0;
        background: #fff;
        box-shadow: none;
        padding: 0px;
        /* height: calc(100vh - 4.3125rem); */
        /* width: 14.5rem; */
        overflow: auto;
    }

    .profile_btm_link_tourside_bar-accodion .profile_btm_link_tourside_bar li {
        margin-top: 3px;
        font-size: 16px;
        font-weight: 400;
    }

    .provider_account_main .profile_btm_link_tourside_bar {
        width: max-content;
        height: auto;
    }

    .home-dis .search-list {
        /* top: 74px;
        left: 30%; */
        width: 100%;
    }

    .culture_listing_fillter .page_filter_box li {
        list-style: none;
    }

    .text_image_loc {
        display: none;
    }

    .heading_search_inner h2 {
        font-size: 54px;
    }

    .row.new__vpagebuilder {
        flex-direction: column-reverse;
        gap: 15px;
    }

    .meet-pickup-main {
        padding: 52px 15px 75px 15px;
    }

    .product_builderbody {
        padding: 2% 2%;
    }

    /* new mytket changes s */
    .page__heading h2 {
        font-size: 50px;
    }

    .bottom-border-checkout .checkout_product_img {
        margin-bottom: 10px;
    }

    .checkout_product_detail {
        padding-left: 0px;
    }

    .user_edit_main .tax_list li {
        font-size: 12px;
    }

    .activity_details .checkout_product_detail_list .list li {
        font-size: 13px;
    }

    .yourvoucher_section h2 {
        font-size: 24px;
    }

    .yourvoucher_section h2 {
        font-size: 26px;
    }

    .cartyour_bookdetail h4 {
        font-size: 20px;
    }

    /* new css s 14-3-24 */
    .modal-content .modal-body-main-sticky.modal-body {
        display: block;
    }

    .all_mobile_data .modal-header {
        padding: 0px;
    }

    .search-icon-mobile-sticky button {
        background: none;
        border: none;
    }

    .modal-body-main-sticky .input_search .btn_search {
        background: lightgray;
    }

    .sort__innerInfo {
        display: block;
    }

    .sorting-option-div {
        margin-top: 10px;
    }

    /* new css 26-3-24 */
    .order_details_table_order {
        padding: 7px;
    }

    .yourvoucher_section.order_details_table_order h4 {
        font-size: 20px;
    }

    .login_popup .modal-content {
        width: 100%;
        left: 0px;
    }

    .login_inner_modal .sign-up-modal-second {
        padding-left: 18px;
    }

    .thankyou-new .cartyour_bookdetail {
        margin-top: 10px;
    }

    .login_inner_modal .sighn-up-phonecode {
        padding-right: 15px;
    }

    /* 20-3-24 */

    .info_date_button .text_one_banner h2 {
        margin-top: 0px;
        font-size: 22px;
        padding-top: 8px;
    }

    .search-list.home-main-newlist {
        width: 38%;
    }

    .listing__Page-banner::before {
        display: none;
    }

    .new-banner-supplier .div-blank{
        display: none;
    }
    .new-banner-supplier .text-data-new{
        width: 100%;
    }
    .sell-tourysta-section .card-data-main .card-data-grid .card-main p{
        font-size: 16px;
    }
    .sell-tourysta-section .card-data-main{
        margin-top: 50px;
    }
    .system-slider-main{
        margin-top: 50px;
    }
    .ActivitiesList-main-supplier .activites-bg-new{
        padding-bottom: 50px;
    }

    /* ===== 19-09-2024 ashu ======== */
    .subscribe_info_main .subscribe_info_outer .subscription_fields_part input{
        width: 100%;
    }


}

@media screen and (max-width:870px) {
    .new_animation_bestof span {
        left: 16%;
        font-size: 57px
    }

    .activity-provider-details .Contact_all_button span {
        margin: 0 9px
    }

    .activity-provider-details .Contact_all_button button {
        padding: 8px 18px
    }

    .expand_text h1 {
        font-size: 33px
    }

    .expand_box_main h4 {
        font-size: 26px
    }

    .company_title h2 {
        font-size: var(--heading-big-24)
    }

    .company_content p {
        font-size: var(--heading-mid-14)
    }

    .part_heading h1 {
        font-size: 34px
    }

    .joinus_box_conten p {
        max-width: 100%
    }

    .guide_newbanner_testimonial_slider .owl-carousel .owl-nav button.owl-next {
        margin-left: 0
    }
}

@media screen and (max-width:768px) {
  
    :root {
        --heading-big-38: 1.7rem
    }

    .header_number_price_main.d-flex {
        display: none !important
    }

    .number_text_product {
        justify-content: flex-start
    }

    .MuiDrawer-root.MuiDrawer-modal,
    .activity_inner_info,
    .affiliate_profile_main .search_header_main,
    .example_section,
    .group_main_box,
    .mobile-heading-text,
    .new_animation_bestof.mobile_animation,
    .number_heading_commission_text,
    .number_price,
    .recommended_list_info,
    .timesshow_flex,
    .valid_form {
        display: block
    }

    .new-dis-box {
        width: 100%;
        margin: 15px 0
    }

    .WhyChoose_heading h2,
    .activity-provider-details .also_like_main,
    .mobile-heading-text p {
        margin: 0
    }

    .login_main_page {
        padding: 0;
        height: auto;
        min-height: 100vh
    }

    .login_inner {
        height: auto
    }

    .banner_top_new,
    .desktop_filter_main,
    .expand_image,
    .footer_copy_left,
    .icon_home,
    .montian_image_main,
    .mountain_cloud_btn,
    .mountain_img_sec,
    .new_animation_bestof.desktop_animation,
    .new_sort_by,
    .search_header_main,
    .testimonial_left_side:after,
    .work_main_box::before {
        display: none
    }

    /* .search_header_main .input_search-new {
        display: none
    } */

    .banner_div_text h2.title_animation {
        font-size: 70px;
        margin-top: 40px
    }

    .mountain_img_sec {
        height: 69%
    }

    .montian_image_main {
        height: 54%;
        top: 46%
    }

    .travelPicLightBox{
        width: 380px;
    }
    @keyframes manMountain {
        from {
            background-position: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 50%
        }

        to {
            position: absolute;
            width: 100%;
            height: 69%;
            top: 43%;
            left: 0;
            right: 0;
            margin: 0 auto
        }
    }

    .banner_menu_btn {
        position: fixed;
        left: 0;
        transform: translate(0, 0);
        display: block
    }

    .banner_all_tab {
        backdrop-filter: blur(0px);
        border-radius: 0;
        overflow: scroll;
        padding: 0
    }

    .banner_menu_btn a {
        display: flex;
        align-items: center;
        margin-right: 0;
        border-radius: 0;
        font-weight: 400;
        padding: 11px 31px
    }

    .RecommendedTours_main,
    .adventure_section,
    .affiliate_section,
    .product_info_inner .also_like_main,
    .testimonial_main {
        margin: 30px 0
    }

    .Checkout_main_page .booking_summary,
    .footer_title.contactList {
        margin-top: 20px
    }

    .Cultural_Main .owl-carousel .owl-nav button.owl-next {
        right: -25px
    }

    .Cultural_Main .owl-carousel .owl-nav button.owl-prev {
        left: -25px
    }

    .header_menu_btn {
        text-align: end
    }

    .banner_main_bg {
        background-image: url("../../images/bannerMobile.png")
    }

    .highlightsytab_main .tab_new_inner,
    .tags_count {
        background: 0 0
    }

    .new_animation_bestof p,
    .new_animation_bestof span {
        font-size: 55px
    }

    .new_animation_bestof {
        display: block;
        width: 100%;
        left: 0;
        transform: none;
        text-align: center
    }

    .banner_inner_text h2 {
        font-size: 48px;
        line-height: 130.1%
    }

    .option_input textarea,
    .recommended_list_img img,
    .timesshow_flex .availability_info {
        width: 100%
    }

    .recommended_list_content {
        margin-left: 0;
        margin-top: 15px
    }

    .desktop_grid_new {
        flex: 0 0 100.666667%;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px
    }

    .Cultural_Main .Cultural_Main_heading h2 {
        line-height: 38px
    }

    .Cultural_Main {
        margin: 30px 0 20px
    }

    .User_sidebar_main,
    .guide_newbanner_left_side p,
    .service_box {
        margin-bottom: 20px
    }

    .button_logout,
    .tags_box {
        margin-top: 0
    }

    .Cultural_Main .item {
        margin: 7px 0 50px
    }

    .slider_animation img {
        height: 100px
    }

    .Experience_Text h2 {
        font-size: 40px;
        line-height: 90.1%
    }

    .highlightsytab_main .Recommended_tab_inner .nav-tabs .nav-link {
        color: #212529
    }

    .Recommended_tab_inner .nav-tabs .nav-link.active {
        color: #fff
    }

    .cart_product_list .pl-0 {
        padding-left: 15px !important
    }

    .cart_product_list .cart_prouct {
        margin-top: 5px
    }

    .cart_product_list .inner_info_cart {
        margin-bottom: 15px
    }

    .activity-provider-right .prd_cart_star {
        margin-top: 14px
    }

    .activity-provider-details .notice_div {
        margin: 15px 0
    }

    .advertisement-content_new button {
        padding: 6px 21px
    }

    .input_edit input.form-control,
    .right_profile_right1 {
        margin-left: 0
    }

    .choose_section {
        padding: 15px
    }

    .choose_heading h1 {
        padding-top: 25px
    }

    .service_main_box {
        flex-wrap: wrap
    }

    .service_box {
        width: calc(50% - 13px)
    }

    .our_story {
        padding-top: 15px
    }

    .blog_details_content h1 {
        max-width: 100%;
        font-size: 24px
    }

    .blog_details_img h2 {
        max-width: 100%;
        font-size: 22px
    }

    .pre_button img {
        transform: translate(-16%, 3%)
    }

    .next_button img {
        transform: translate(31%, 4%)
    }

    .affiliate_text h1 {
        font-size: 30px
    }

    .mytket_heading h1 {
        font-size: 30px;
        max-width: 100%
    }

    .Contact_info_right_bg,
    .Contact_right_bg_color {
        border-radius: 8px
    }

    .ContactInfo_inner {
        top: 0
    }

    .activities_info p {
        text-align: start
    }

    .haeder_new_btn .lang_drop_down {
        margin-right: 0
    }

    .testimonial_left_side h2 {
        line-height: 30px
    }

    .tags_inner_wid {
        display: flex;
        border-radius: 10px;
        align-items: center;
        color: #fff;
        margin: 0;
        background: 0 0;
        width: 50%
    }

    .tags_count {
        width: auto;
        height: auto
    }

    .tags_box {
        margin-bottom: 20px
    }

    .footer-section .copyright_txt p {
        text-align: center
    }

    .input_search_expand .button_search {
        padding: 11px 12px 13px 16px
    }

    .expand_box_main span {
        font-size: 12px
    }

    .expand_box_main h4 {
        font-size: 26px
    }

    .part_heading h1 {
        font-size: 28px
    }

    .company_title h2 {
        font-size: 20px
    }

    .company_content p {
        font-size: 14px
    }

    .Newsletter_input input {
        max-width: 50%;
        padding: 10px
    }

    .part_section {
        padding: 50px 0
    }

    .guide_newbanner_main {
        padding: 30px 0 60px
    }

    .affiliate_image {
        margin-top: 15px
    }

    .haeder_new_btn {
        justify-content: space-between
    }

    .checkbox_product_title_last {
        margin-top: 4%;
        margin-left: 18px
    }

    .example_section_list_item ul {
        padding-left: 10px
    }

    .add_btn_important {
        margin-top: 96px
    }

    .cart_product_list .radt_price {
        margin-top: 10px
    }

    /* changes 28-12-23 */
    .Wishlist_main_componemt .div_last_main {
        flex-direction: column;
    }

    .search_header_main .header--main .search-mobile-dashtop {
        display: block;
    }

    .search_header_main::before .search-icon-mobile {
        display: block !important;
    }

    .search-icon-mobile {
        display: block;
    }

    .all_mobile_data .modal-header {
        padding: 0px;
    }

    .search-icon-mobile button {
        background: none;
        border: none;
        padding: 0px;
    }

    .modal-body-main .input_search .btn_search {
        background: lightgray;

    }

    .modal-content .modal-body-main.modal-body {
        display: block !important;
    }

    .home_main_page .hide-searchlist {
        display: none;
    }

    .modal-body-main.modal-body .search-list {
        position: absolute;
        z-index: 166;
        /* top: 85px; */
        left: 0%;
        width: 100%;
    }

    .modal-body-main.modal-body {
        display: block;
    }

    /* .searchlist-location-icon.col-md-2 {
        width: auto !important;
    } */


    /* By Shruti *****/
    .title_wrapper {
        margin: 0.5rem 0 0.5rem 0;
        font-weight: 700;
        font-size: 1.5rem;
    }

    .overview_wrapper .description,
    .meet_pickup_wrapper .departure_container,
    .what_to_expect_wrapper .details__ntls,
    .addition_info_wrapper .info_list_wrapper,
    .cancellation_policy_wrapper .policy_list_wrapper,
    .what_include_wrapper .content_list_wrapper .include_list .includes p,
    .what_include_wrapper .content_list_wrapper .include_list .includes {
        font-size: 14px;
    }

    .what_include_wrapper,
    .meet_pickup_wrapper,
    .what_to_expect_wrapper,
    .addition_info_wrapper,
    .cancellation_policy_wrapper {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .traveller_grid {
        grid-template-columns: auto auto;
    }

    .meet_pickup_wrapper .inner_title {
        font-size: 1.2rem;
    }

    .product_info_inner .title_with_review h2 {
        margin-top: 5px;
    }

    .overview_wrapper {
        padding-bottom: 1rem;
        padding-top: 0rem;
    }

    .meet_pickup_wrapper .pickup_points .locationSearchBar .control {
        width: 100%;
    }

    /* **** */

    /* 13-2-24 */
    .activity_details .checkout_product_details_wrapper .row .col-12>.row {
        padding: 20px 0;
        border-bottom: 1px solid #d9d9d9;
        gap: 12px;

    }

    .stickysection_culturedetail,
    .checkavility_stickyflex,
    .product_info_inner .review_subtitle .divider_line {
        display: none;
    }

    .product_info_inner .review_subtitle {
        flex-direction: column;
        align-items: flex-start;
    }

    .product_info_inner .review_subtitle .review_link {
        padding: 0;
    }

    .product_info_inner .title_with_review h2 {
        margin-bottom: 5px;
    }

    .page__heading h2 {
        font-size: 50px;
    }

    .new-list-catgory {
        margin-top: -14%;
    }

    .info_date_button {
        flex-direction: column;
        align-items: flex-start;
    }

    .info__date_inner {
        width: 100%;
    }

    /* .new-builder-language .product-language-note .top-header-info-box .language-name img{width: 8%;} */

    /* new mytket changes s */
    .heading_search_inner h2 {
        font-size: 38px;
    }

    .RecommendedTours_main .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .Cultural_Main .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .sort__innerInfo {
        display: block;
        margin-bottom: 20px;
    }

    .sorting-option-div {
        gap: 10px;
    }

    .cart_product_list .radt_price {
        margin-top: 0px !important;
        padding-left: 16px;
    }

    .activity_details .checkout_product_details_wrapper .row .col-12>.row {
        gap: 0px;
    }

    .sorting-option-div {
        justify-content: space-between !important;
        gap: 20px;
        margin-top: 10px;
        width: 100%;
    }


    /* new css s */
    .order_list_product .order_list_inner table tr td:not(:first-child) {
        display: flex;
    }

    .main_table tr {
        display: grid;
        grid-template-columns: 100%;
        text-align: left;
        justify-content: start;
    }


    /* new css 28-3-24 */

    .page__heading h2 {
        padding-top: 4px;
    }

    .search-list.home-main-list {
        display: none;
    }

    .search-list.home-main-newlist {
        display: none;
    }

    .modal-search-list{
        display: block;
    }

    .header-modal-inner-v .search-main-div {
        display: flex;
    }


    .grid-choose {
        width: 45%;
    }

    .thankyou_header .text-inner h2{
        font-size: 32px;
    }

}

@media screen and (max-width:767px) {

    /* ashu25  */
    .banner_test_ones {  
        /* margin-top: -45px; */
        margin-top: 80px;
        position: absolute;
    }
    .banner_test_ones img{
        display: none;
    }
    .culture_list_info_new .info_date_button .heading_search_inner h2 {
        margin-top: 120px;
    }
    /* ================================ */

    .last_cardmaincardflex .third_lastcardmaindiv {
        width: 100%;
    }


    .modal_review_image .modal-body
    {
        height: auto;
    }

    div#cancellation-policy-div ul {
        display: block;
    }

    div#cancellation-policy-div ul li {
        width: calc(100% - 0px);
    }

    .info_date_button .info__date_inner {
        width: 100%;
    }
    .culture_list_info_new .info_date_button .info__date_inner {
        width: 100%;
        margin-bottom: 0px;
    }

  
    .yourvoucher_section {
        margin-top: 20px;
    }

    .thankyou_cartpage .cartone_img img {
        height: 150px;
        margin-bottom: 20px;
    }

    /* // ----- */

    .expand_conten,
    .joinus_box_main {
        text-align: center;
        max-width: 100%
    }

    .activity-provider-details .notice_div_left,
    .activity-provider-details .notice_div_right {
        background: 0 0
    }

    .activity-provider-details .notice_div {
        background: #f9f9f9;
        border-radius: 8px
    }

    .choose_box img {
        margin-bottom: 15px
    }

    .Country_menu,
    .choose_box h2,
    .user_edit_main .input_all_edit input.input_first {
        margin-bottom: 10px
    }

    .footer_logo img {
        width: 90px;
        display: none
    }

    .prd_list_heading h2 {
        font-size: var(--heading-big-24)
    }

    .partner_slider .owl-carousel .owl-item .Partner_image img {
        width: 100px
    }

    .user_edit_main {
        padding: 17px 16px
    }

    .Wishlist_main_componemt .span_tag {
        padding: 8px 10px;
        margin-left: 4px
    }

    .adventure_section::after,
    .our_story_section::before {
        display: none
    }

    .card_form_info .new_input_checkout {
        margin-top: 10px
    }

    .work_bg_img {
        display: contents
    }

    .mytket_section {
        padding: 40px 0
    }

    .SendUs_message_left {
        width: 100%;
        padding: 40px 0 30px
    }

    .Contact_info_right {
        width: 100%
    }

    .guide_newbanner_testimonial_right_side {
        background: #fff;
        margin-top: 25px
    }

    .guide_newbanner_testimonial_slider .owl-carousel .owl-nav button.owl-next {
        margin-left: 15px
    }

    .expand_image img {
        border-radius: 8px
    }

    .expand_image,
    .success_section {
        margin-top: 27px
    }

    .joinus_box_main {
        display: block;
        max-height: 124px
    }

    .joinus_box_conten {
        padding-left: 0
    }

    .image_event_box {
        display: none;
        margin-bottom: 0
    }

    .part_heading h1 {
        font-size: 34px;
        max-width: 100%
    }

    .company_section_box {
        max-width: 100%;
        min-height: 0;
        padding-bottom: 3px;
        margin-bottom: 20px
    }

    .expand_box_main {
        display: block
    }

    .ContactInfo_inner {
        display: block;
        box-shadow: none
    }

    .contactinfo_social_icon {
        padding: 5% 0 0
    }

    .flex_availabillity {
        flex-direction: column;
        gap: 0
    }

    .dashboard_section_main {
        margin-bottom: 12px
    }

    .monthly_activity_chart {
        height: auto
    }

    .doughnut_text h5 {
        top: 42%;
        left: 44%;
        font-size: 18px
    }

    .doughnut_text h6 {
        top: 50%;
        left: 46%;
        font-size: 16px
    }

    .latest_right_main {
        margin-top: 15px
    }

    .productbulid_header {
        flex-direction: column;
    }

    /* new mytket changes  */
    .user_edit_main .tax_list li {
        display: flex;
    }

    /* 20-3-24 new css s */
    .google-map-code .gm-style .gm-style-iw-c {
        top: 100px !important;
    }

    .my-user-map .tour-name-and-date {
        position: relative;
        margin-top: 15px;
    }

    /* 26-4-24 s */
    .cartname_leftpart {
        margin-top: 10px;
    }

    .booking_top_info h2 {
        font-size: 28px;
    }

    .cartone_img {
        padding: 26%;
    }

    .pricing_step_two .priceDateSection {
        display: block;

    }

    .priceDateSection-new {
        display: block;

    }

    .image-slider-grid{
        flex-direction: column !important;
        gap: 20px;
    }

    .image-inner-main{
        width: 100%;
    }
    .image-rating-data{
        width: 100%;
    }
    .help_banner .help_text h1 { 
        font-size: 2.8em;
    }
    .help_banner .help_text h4 {
        font-size: 26px;
    }



    .new-text-data:after{
        display: none;
    }
    .new-text-data .main-text-data p{
        max-width: 100%;
    }
    .new-text-data .main-text-data{
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .new-text-data-mid:after{
        /* height: 162px;
        bottom: -16%; */
        bottom: -139px;
        padding: 8em;
    }
    .our-journey-text{
        margin-top: 150px;
    }
    .Journeybtm-icon-grid .icon-grid-data{
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }
    .our-journey-text .image-bottom-new{
        width: 100%;
    }
    .new-banner-supplier .text-data-new button{
        margin-top: 11px;
        font-size: 16px;
    }
    .new-banner-supplier .text-data-new p{
        font-size: 30px;
    }
    .sell-tourysta-section .data-top-main h2{
        font-size: 30px;
    }
    .sell-tourysta-section .data-top-main p{
        font-size: 16px;
        max-width: 100%;
    }
    .sell-tourysta-section .card-data-main h2{
        font-size: 30px;
    }
    .sell-tourysta-section .card-data-main p{
        font-size: 16px;
        max-width: 100%;
    }
    .sell-tourysta-section .card-data-main .card-data-grid {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .sell-tourysta-section .card-data-main .card-data-grid .card-main{
        width: 100%;
    }
    .sell-tourysta-section .btn-bottom-data button{
        margin-top: 40px;
    }
    .slider-inner-data{
        width: 100%;
    }
    .system-slider-main .data-top-main h2{
        font-size: 30px;
    }
    .system-slider-main .data-top-main p{
        font-size: 16px;
        max-width: 100%;
    }
    .ActivitiesList-main-supplier .list-data-new ul li{
        width: 48%;
    }
    .ActivitiesList-main-supplier .heading-top-main h2{
        font-size: 30px;
    }
    .ActivitiesList-main-supplier .list-data-new ul li a{
        font-size: 16px;
    }
    .AfiliateBanner-main-data .text-data p{
        font-size: 30px;
    }
    .grow-media-section:after{
        top: -31px;
        padding: 5em;
    }
    .AfiliateSlider-new-page:after{
        top: -223px;
        padding: 6em;
    }
    .AfiliateSlider-new-page .system-slider-main .data-top-main h2{
        max-width: 100%;
    }
    .AfiliateSlider-new-page .slider-inner-data{
        margin: 40px auto 0px;
    }

    /* ====== ashu ====== */
    .Journeybtm-icon-grid .icon-grid-data .icon-one::after{
        display: none;
    }
    .Journeybtm-icon-grid .icon-inner{
        z-index: 5;
    }


}

@media screen and (max-width:600px) {
    .banner_div_text h2.title_animation {
        font-size: 50px;
        margin-top: 40px
    }

    .banner_div_text p {
        font-size: 36px
    }

    .banner_div_text h2 {
        font-size: 120px
    }

    .new_animation_bestof span {
        font-size: 51px;
        left: 8%;
        top: auto
    }

    .montian_image_main {
        height: 35%;
        top: 65%
    }

    .mountain_img_sec {
        height: 80%
    }

    .mountain_cloud_btn {
        height: 33%;
        top: 69%
    }

    @keyframes manMountain {
        from {
            background-position: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 50%
        }

        to {
            position: absolute;
            width: 100%;
            height: 80%;
            top: 43%;
            left: 0;
            right: 0;
            margin: 0 auto
        }
    }

    @keyframes size {
        from {
            font-size: 50px
        }

        to {
            font-size: 120px
        }
    }

    .choose_box p {
        margin-bottom: 15px
    }

    .footer-section .footer_title h4 {
        margin-bottom: 10px;
        margin-top: 25px
    }

    .Cultural_Main .owl-carousel .owl-nav button.owl-prev {
        left: -22px;
        top: 38%
    }

    .Cultural_Main .owl-carousel .owl-nav button.owl-next {
        right: -18px;
        top: 38%
    }

    .signup_parent .tab-content {
        height: 400px
    }

    .service_box {
        width: calc(100% - 0px)
    }

    .order_details_main .order_inner {
        flex-direction: column-reverse
    }

    .order_all_icon {
        margin-top: 25px
    }

    /* header search shivi ******************** */
    .modal-body-main.modal-body {
        top: 29px;
    }

    .sort__innerInfo {
        display: block;
    }

    .sorting-option-div {
        justify-content: flex-start;
        margin: 15px 0px;
    }

    .provider_my_tour .text_banner {
        /* display: block; */
    }

    .unique-product-react-quill {
        margin-bottom: 105px;
    }


    .thankyou_header .flex-grid{
        flex-direction: column;
        gap: 15px;
    }

    .thankyou_header .image-inner {
        width: 50%;
    }




}

@media screen and (max-width:567px) {
    .input-error{
        width: 100%;
    }
    /* ---- as15 -- */
    .knowbefore_gosection .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {

        width: 100%;
    }

    .knowbefore_gosection .css-1c35hjw-MuiPaper-root-MuiAccordion-root:last-of-type {
        width: 100%;
    }

    /* ----- */
    .expand_box_main,
    .our_team_heading {
        text-align: center
    }

    .Wishlist_main_componemt .prd_star_rating,
    .edite_main_box,
    .example_section_location,
    .expand_box_main,
    .group_main_inner_main,
    .joinus_img,
    .main_photos,
    .product_info_main .prd_star_rating,
    .provider_all_product .Wishlist_main_componemt .prd_star_rating,
    .timesshow_flex {
        display: block
    }

    .cart_product_list .cart_prd_tags {
        display: block;
        margin: 10px 0
    }

    .Wishlist_main_componemt .span_tag,
    .provider_my_tour .span_tag_active {
        margin-top: 5px;
        margin-left: 0
    }

    .mountain_img_sec {
        height: 89%
    }

    .banner_inner_text h2 {
        font-size: 35px;
        line-height: 120.1%
    }

    .banner_inner_text {
        padding: 10% 0
    }

    .banner_inner_text p {
        max-width: 100%
    }

    @keyframes manMountain {
        from {
            background-position: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 50%
        }

        to {
            position: absolute;
            width: 100%;
            height: 89%;
            top: 43%;
            left: 0;
            right: 0;
            margin: 0 auto
        }
    }

    .paginate_all ul li.next {
        margin-left: 20px
    }

    .paginate_all ul li.previous {
        margin-right: 20px
    }

    .paginate_all ul li {
        margin-right: 7px
    }

    .div_experience_new .owl-theme .owl-dots {
        bottom: 10px
    }

    .banner_div_text h2 {
        font-size: 70px
    }

    @keyframes size {
        from {
            font-size: 50px
        }

        to {
            font-size: 70px
        }
    }

    .new_animation_bestof span {
        line-height: 50px;
        top: 80px;
        left: 3%;
        font-size: 40px
    }

    .prd_star_rating .list_rating {
        display: flex;
        margin-bottom: 5px
    }

    .image_slider_main .btn_show_more {
        top: 7px;
        left: 10px
    }

    .image_slider_main .btn_show_more button {
        padding: 6px 8px
    }

    .image_slider_main .btn_wishlist {
        bottom: 8px;
        right: 8px
    }

    .image_slider_main .btn_wishlist button {
        padding: 7px 11px
    }

    .activity-provider-details .Contact_all_button {
        display: flex;
        flex-direction: column;
        text-align: center
    }

    .login_form_box {
        padding: 15px
    }

    .header_menu_btn a:nth-child(2) {
        padding: 11px 26px;
        margin-left: 30px
    }

    .aboutus_text h1 {
        font-size: 25px;
        line-height: 27px
    }

    .our_team_heading {
        margin-bottom: 25px
    }

    .our_team_section {
        margin-top: 35px;
        padding-top: 26px
    }

    .joinus_img {
        padding-top: 0
    }

    .provider_all_product .Wishlist_main_componemt .span_tag,
    .provider_all_product .provider_my_tour .span_tag_active {
        margin-left: 0
    }

    .header_logo_image img {
        width: 90px
    }

    .Experience_Text h2 {
        font-size: 25px
    }

    .tags_inner_wid {
        width: 100%
    }

    .joinus_box_conten p {
        font-size: 12px
    }

    .joinus_box_conten h3 {
        font-size: var(--heading-big-20)
    }

    .joinus_box_main {
        max-height: 153px
    }

    .Newsletter_input input {
        max-width: 100%;
        padding: 10px;
        margin-right: 0;
        margin-bottom: 15px
    }

    .new_accorian .show_availabillity .tag_span span {
        top: -35%
    }

    .main_photos {
        gap: 0
    }

    .example_section_list_item p {
        padding-left: 15px
    }

    .icon_title_modal {
        width: 300px
    }

    .number_heading_text {
        font-size: 13px
    }

    .search_minutes_option .MuiOutlinedInput-root .MuiAutocomplete-input {
        margin-top: 0
    }

    .prd_star_rating .span_tag_not_approved_new {
        width: 37%;
    }

    .edite_delete_section {
        width: 75%;
    }

    .new-list-catgory {
        margin-top: -22%;
    }

    .sorting-option-div {
        display: block;
    }

    /* .sorting-option-div{
        font-size: 40px;
    } */

    .heading_search_inner h2 {
        font-size: 40px;
    }

    .prd_Sort_by {
        justify-content: flex-start;
        margin: 15px 0px;
    }

    .provider_my_tour .banner_button_main {
        flex-direction: column;
        gap: 20px;
    }

    .provider_my_tour button.add_icon_button-builder,
    .provider_my_tour button.add_icon_button {
        margin-right: 0;
    }

    .banner_button_main button {
        width: 100%;
    }

    .productbulid_header .head_rightname h4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid;
    }

    .productbulid_header .head_rightname .preview_header_link,
    .productbulid_header .head_rightname button {
        border-left: none;
        padding-left: 0px;
        margin-left: 0px;
    }

    .time_data_main-attraction .transport-time-div {
        margin-left: 0;
    }

    .time_data_main-attraction .icon_search_button {
        flex-direction: column;
    }

    .time_data_main-attraction .select-field {
        margin-left: 0;
    }

    .time_data_main-attraction .transport-time-div,
    .time_data_main-attraction .transport-by-div,
    .time_data_main-attraction .transport-type-div,
    .time_data_main-attraction .pricing-schedule-cancel-time input {
        width: 100%;
    }

    .inclusion_models .hourinputdiv input {
        width: 18%;
        font-size: 17px;
    }

    /* new mytket changes s */
    .Cultural_Main .grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .RecommendedTours_main .grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .page__heading h2 {
        font-size: 37px;
    }

    .Faq_main {
        padding: 0px;
    }

    .product_builderbody h1 {
        font-size: 26px;
    }

    .activity_productdetail h6 {
        font-size: 18px;
    }

    .activity_productdetail .hourinputdiv select {
        width: 40%;
    }

    .activity_productdetail .hourinputdiv input {
        width: 20%;
    }

    /* new css s 14-3-24 */
    .sort_two .filter_mobile {
        margin-top: 10px;
    }


    /* 26-3-24 s new */
    .go-back-btn {
        text-align: start;
    }

    .upcoming_detail .home-btn {
        position: relative;
        right: 0px;
        margin-top: 0px;
    }

    .meet-pickup-main .inclueded_partsection p {
        display: flex;
    }

    .autocomplete-product-details {
        width: 100%;
    }

    .grid-choose {
        width: 100%;
    }

    .categories .cat-search{
        width: 100%;
    }
    .morehelp_inputsection .inputto_divflex{
        flex-direction: column;
    }


    .new-text-data .main-text-data h2{
        font-size: 40px;
    }
    .new-text-data .main-text-data p{
        font-size: 16px;
    }
    .new-text-data-mid .main-text-data h2{
        font-size: 25px;
    }
    .new-text-data-mid .main-text-data p{
        font-size: 16px;
    }
    .our-journey-text {
        margin-top: 80px;
    }
    .heading-top h2{
        font-size: 32px;
    }
    .our-journey-text .main-text-data{
        margin-top: 30px;
    }
    .our-journey-text .main-text-data h2{
        font-size: 25px;
    }
    .our-journey-text .main-text-data p{
        font-size: 16px;
    }
    .new-text-data-mid:after {
        bottom: -80px;
        padding: 5em;
    }

    /* ======= 19-09-2024 ashu======= */
    .subscribe_info_main .subscribe_info_outer .subscription_fields_part button {
        padding: 9px 16px;
    }
    .subscribe_info_main .subscribe_info_outer .subscription_fields_part input {
        padding: 10px 20px;
    }

}

@media screen and (max-width:425px) {

    /* ---------- as25 -------------- */
    .last_cardmaincardflex .fist_imagemaindiv {
        width: 100%;
    }
    .last_cardmaincardflex .second_detail_mainmiddiv {
        width: 100%;
    }
/* -------------------- */
    .list_Breadcrumb ul li {
        font-size: 14px
    }

    .activities_info p {
        font-size: 12px
    }

    .banner_hedding h1 {
        font-size: 30px
    }

    .contactinfo_social_icon ul {
        gap: 10px
    }

    .input_search_expand .button_search {
        padding: 8px 10px 10px 11px
    }

    .input_search_expand input[type=text] {
        font-size: 13px
    }

    .guide_newbanner_testimonial_slider .owl-theme .owl-nav,
    .new_accorian .show_availabillity .wrapper {
        display: flex;
        justify-content: center
    }

    .guide_newbanner_main {
        padding: 30px 0 20px
    }

    .icon_section {
        display: initial
    }

    .affiliate_profile_main ul {
        margin-left: -42px
    }

    .price_box_number {
        gap: 0
    }

    .starting_time_main .price_box,
    .starting_time_main .timesshow_data h2.mb-3 {
        text-align: center
    }

    .starting_time_main,
    .totle_booking_main {
        display: block
    }

    .new_accorian .show_availabillity h2 {
        text-align: initial
    }

    .descrbActivity_List input[type=radio] {
        margin-top: 17px
    }

    .descrbActivity_List .inline-input-second,
    .descrbActivity_List .inline-input-third,
    .descrbActivity_List .inline-input_one {
        display: flex
    }

    .icon_title_modal {
        width: 235px
    }

    .dashboard_searchbar {
        width: 100%
    }


    /* headre changes 28-12-23 s */
    .header_sticky .header_logo_image img {
        width: 104px;
    }

    .edite_delete_section {
        width: 100%;
    }

    .nav-item .dropdown img {
        margin-right: 0px;
    }

    .booking-history-div-new {
        margin-bottom: 10px;
    }

    .accordiansign {
        margin-top: 25px;
    }

    .unique-product-react-quill {
        margin-bottom: 125px;
    }

    /* new mytket changes s*/
    .btn-last-save .btn-all-save {
        margin-top: 58px;
    }

    .top-header-meet h2 {
        font-size: 27px;
    }

    /* 20-3-24 s */
    .google-map-code .gm-style .gm-style-iw-c {
        top: 170px !important;
    }


    /* 26-3-24 s */
    .booking_top_info h2 {
        font-size: 21px;
    }

    .categories .cat-search input {
        padding: 6px 24px;
    }
    .categories .cat-search button.helpbtn_search {
        margin-left: -46px;
        height: 46px;
        width: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
    .categories .cat-search button.helpbtn_search svg{
        height: 20px;
        width: 20px;
    }


}

@media screen and (max-width:375px) {
    /* ------------- as25 ------------- */

    .flex_cardcheckbtn{
        flex-direction: column;
        gap: 10px;
    }

    .latestblogs_left_bg_img {
        margin-bottom: 25px
    }

    .latestblogs_right_profile2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start
    }

    .right_profile_left2 {
        display: flex;
        justify-content: flex-start;
        align-items: center
    }

    .right_profile_right2 {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-left: 10px;
        padding: 10px 0
    }

    .right_profile_left2 span {
        margin-left: 18px
    }

    .right_profile_right2 span {
        margin-left: 24px
    }

    .right_profile_right2 img {
        width: 100%;
        margin-left: 0
    }

    .provider_my_tour button.add_icon_button {
        /* border-radius: 0; */
        /* padding: 8px 10px;
        gap: 0;
        margin-right: 8px */
    }

    .login_inner_modal .nav {
        flex-wrap: nowrap
    }

    .login_popup .modal-content {
        margin-top: 30%
    }

    .footer-section button.submit_btn {
        position: relative;
        margin-top: 10px;
        width: 100%;
        top: 0;
        transform: none;
        height: 44px
    }

    .expand_text h1 {
        font-size: 28px
    }

    .change_password {
        display: inherit;
        justify-content: space-between;
        margin-top: 5%
    }

    .save_chnages_btn {
        text-align: center;
        margin-top: 22px
    }

    .heder_new_search_affiliate .input_search .btn_search {
        padding: 12px 40px;
        top: 60px;
        left: 0;
        width: 100%
    }

    .Share_icon {
        margin-top: 70px
    }

    .new_accorian .show_availabillity .tag_span span {
        top: -32%
    }

    .number_price {
        display: block
    }

    /* new changes s ********* */
    .mobile_drop_lang {
        margin-right: 0px;
    }

    .order_list_product .main_table_menu {
        overflow-x: scroll;
    }

    .order-cancelled {
        overflow-x: scroll;
    }

    .haeder_new_btn .lang_drop_down .nav-link,
    .language_change_header .nav-link {
        padding: 6px 0px;
    }

    .profile-provider-main .save_chnages_btn {
        margin-top: 138px;
    }
}

@media screen and (max-width:320px) {
    .input_search_expand .button_search {
        width: 100%;
        position: relative
    }

    .footer_socialIcon ul li {
        width: 21px
    }

    .affiliate_money_box {
        flex-direction: column
    }

    .affiliate_money_box p {
        margin-top: 10px;
        text-align: center;
        margin-left: 0
    }

    /* new changes css s */
    .checkbox_product_title span {
        font-size: 12px;
    }
}

@media (min-width:481px) and (max-width:768px) {
    .adven_img1 {
        margin-bottom: 12px
    }

    .adven_text {
        padding-bottom: 0
    }

    .choose_section_box {
        padding: 0
    }

    .adventure_section::after {
        display: none
    }

    .our_team_carousel_section .owl-nav button.owl-next {
        right: -55px
    }

    .our_team_carousel_section .owl-nav button.owl-prev {
        left: -59px
    }
}

@media (min-width:769px) and (max-width:990px) {
    .adven_img1 {
        padding-bottom: 15px
    }

    .adven_text {
        padding-bottom: 0
    }

    .adventure_section::after {
        display: none
    }

    .service_img img {
        width: 84px;
        height: 84px
    }

    .choose_section_box {
        padding: 0
    }

    .our_team_carousel_section .owl-nav button.owl-prev {
        left: -51px
    }

    .our_team_carousel_section .owl-nav button.owl-next {
        right: -51px
    }
}